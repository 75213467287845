import React, { useState, useEffect, } from 'react'
import '../App.css';
import './projects.css';
import { BsArrowDown } from "react-icons/bs";

import con from "../Assets/contact.svg";
import l11 from "../Assets/tad11.jpg";

import l10 from "../Assets/tad10.jpg";
import l16 from "../Assets/tad16.jpg";
import l17 from "../Assets/tad17.jpg";
import l18 from "../Assets/tad18.jpg";
import l7 from "../Assets/tad20.jpg";
import l8 from "../Assets/tad5.jpg";
import { Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../Footer';



const Projects = props => {


    const [button,setButton] = useState(true);
    const [type,setType] = useState('Residential');

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  let projects = [
    {
      "image": l18,
      "name": "SANKEERATH",
      "type": "Residential"
    },
    {
      "image":l17,
      "name": "HOME BHOOJA",
      "type": "Residential"
    },
    {
      "image": l10,
      "name": "HOME 3",
      "type": "Residential"
    },
    {
      "image": l16,
      "name": "DIAGNOSTIC CENTER",
      "type": "Commercial"
    },
    {
      "image": l11,
      "name": "MAGNIFIQ",
      "type": "Offices"
    },
    {
      "image": l7,
      "name": "APOLLO CLINIC",
      "type": "Hospitality"
    },

  ]

  return (
    <div>
    <div style={{paddingLeft:'4vw',paddingTop:'4vw'}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'baseline',marginBottom:25}}>
      <text style={{fontSize:50,fontFamily:'Raleway'}}>Projects</text>
      <img style={{height:30,marginLeft:10}} src={con}></img>
      </div>
      <div style={{display:'flex',width:button?'40vw':'80vw'}}>
      <text style={{fontSize:14}}>We understand the unique character and purpose of each project, our designs are tailored to reflect the aspirations of our clients.</text>
      </div>

      <div style={{marginTop:50,cursor:'pointer',fontSize:14}}>
        <text onClick={()=>{setType('Residential')}} style={{paddingRight:30,color:type=='Residential'?'#c4baa6':''}}>Residential</text>
        <text onClick={()=>{setType('Commercial')}} style={{paddingRight:30,color:type=='Commercial'?'#c4baa6':''}}>Commercial</text>
        <text onClick={()=>{setType('Offices')}} style={{paddingRight:30,color:type=='Offices'?'#c4baa6':''}}>Offices</text>
        <text onClick={()=>{setType('Hospitality')}} style={{paddingRight:30,color:type=='Hospitality'?'#c4baa6':''}}>Hospitality</text>
      </div>
    
      <div style={{display:'flex',flexDirection:button?'row':'column',marginBottom:'1vw',flexWrap:'wrap',marginTop:'5vh'}}> 
      {projects.map((item, index) =><div>{type == item.type? <div className='img1' style={{marginRight:button?'1vw':'',width:button?'30vw':'92vw',marginBottom:button?'1vw':'2vw'}}>
        <img className='img1_img' src={item.image} />
        <div className='text'>
        <text>{item.name}</text>
        </div>
        </div>:null}</div>)}
    </div>
    </div>
 
<Footer/>
   </div>
  );
}

export default Projects;