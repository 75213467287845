import React, { useState, useEffect, } from 'react'
import '../App.css';
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import tad7 from "../Assets/tad7.jpg";
import tad10 from "../Assets/tad10.jpg";
import tad15 from "../Assets/tad15.jpg";
import tad16 from "../Assets/tad16.jpg";
import tad18 from "../Assets/tad18.jpg";
import Footer from '../Footer';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

import Slider from "react-slick";
import l1 from "../Assets/l1.png";
import l2 from "../Assets/l2.png";
import l3 from "../Assets/l3.png";
import l5 from "../Assets/l5.png";
import l4 from "../Assets/l4.png";
import l6 from "../Assets/l6.png";
import l7 from "../Assets/l7.png";
import l8 from "../Assets/l8.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const fruits = ["Apple", "Mango", "Banana", "GFG"];

const data = [
  {
    name:'Rahul',
    company:'Magnifiq',
    reveiew:"Working with Triple A Design was a great experience. They understood our vision and delivered an office space that perfectly balances functionality and style. We're extremely happy with the final result."
  },
  {
    name:'Bala Reddy',
    company:'Capital Trust',
    reveiew:'Triple A Design made sure every part of the design fit our needs. The end result is a modern, efficient office that surpassed our expectations.'
  },
  {
    name:'Darshitha',
    company:'Home Bhooja',
    reveiew:"Triple A Design turned our apartment into a beautiful, functional space. The attention to detail and smooth process made it all effortless. We're thrilled with the outcome."
  }
  ]

function Home() {


    const [button,setButton] = useState(true);

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

  

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
<div
      className={className}
      onClick={onClick}
    ><BsArrowRight style={{ ...style, color: "black", fontSize: "20px" }} /></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
<div
      className={className}
      onClick={onClick}
    ><BsArrowLeft style={{ ...style, color: "black", fontSize: "20px" }} /></div>
  );
}

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};



useEffect(()=>{
  showButton();
  Aos.init({duration:1000});
},[]);
  window.addEventListener('resize',showButton);




  return (
    <div style={{fontFamily:'Raleway'}}>
  {/*   <div  className={button?'bg':'mob'} style={{display:'flex',flexDirection:button?'row':'column',justifyContent:'center',alignItems:'center',color:'white',textAlign:button?'':'center'}}>   
           <text style={{paddingRight:button?'20vh':'',fontFamily:'Crimson',fontSize:26,width:button?'40vw':'90vw',lineHeight:1.2}}>Architecture, Design & Comprehensive Renovations</text>
            <text style={{fontWeight:500,padding:20,width:button?'40vw':'90vw'}}>We offer innovative spaces that transcend mere functionality</text> 
    </div>*/ }
 
    <Carousel>
  <Carousel.Item style={{height:button?'100vh':'40vh'}} interval={1000}>
    <img 
       className="d-block w-100" style={{height:button?'100vh':'40vh',  objectFit: "cover"}}
      src={tad16}
      alt="First slide"
    />

  </Carousel.Item>
  <Carousel.Item style={{height:button?'100vh':'40vh'}} interval={1000}>
    <img 
       className="d-block w-100" style={{height:button?'100vh':'40vh',objectFit: "cover"}}
      src={tad18}
      alt="Third slide"
    />

  </Carousel.Item>
  <Carousel.Item style={{height:button?'100vh':'40vh'}} interval={1000}>
    <img 
       className="d-block w-100" style={{height:button?'100vh':'40vh',objectFit: "cover"}}
      src={tad7}
      alt="Third slide"
    />

  </Carousel.Item>
</Carousel>

     <div style={{backgroundColor:'#1f2122',color:'#fafafa'}}>
        <div style={{display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center',alignItems:'center',paddingTop:button?'35vh':'15vh',paddingBottom:button?'35vh':'15vh'}}>
        <text style={{fontSize:12,letterSpacing:5,color:'#c4baa6'}}>OUR VISION</text>
      <text data-aos="fade-up"   style={{fontSize:14,lineHeight:1.8,width:button?'50vw':'80vw',paddingTop:40}}>At the heart of Triple A Design our vision is to create spaces that inspire, enrich, and endure. We envision architecture as more than just structures; it's a language that communicates the essence of a place and the aspirations of its inhabitants.</text>
        </div>

    </div>

 
    <div style={{textAlign:button?'center':'',display:'flex',borderBottom:'1px solid black'}}>
    {button? <img style={{width:button?'40vw':'90vw',borderRight:'1px solid black'}} src={tad15}/>:null}
          <div data-aos="zoom-in" style={{display:'flex',flexDirection:'column',justifyContent:'space-evenly',padding:button?'10vh':'5vh'}}>
          <text style={{fontSize:12,letterSpacing:5,paddingBottom:25}}>OUR SERVICES</text>  
      <div  style={{textAlign:'center',paddingLeft:button?70:'',display:'flex',alignItems:'center'}}>
      <text style={{marginRight:25,fontSize:16,color:'#c4baa6'}}>01</text>
      <text style={{fontWeight:'500',fontSize:16}}>Architecture</text>
      </div>
      <div  style={{textAlign:'center',paddingLeft:button?70:'',display:'flex',alignItems:'center'}}>
      <text style={{marginRight:25,fontSize:16,color:'#c4baa6'}}>02</text>
      <text style={{fontWeight:'500',fontSize:16}}>Interior Design</text>
      </div>
      <div  style={{textAlign:'center',paddingLeft:button?70:'',display:'flex',alignItems:'center'}}>
      <text style={{marginRight:25,fontSize:16,color:'#c4baa6'}}>03</text>
      <text style={{fontWeight:'500',fontSize:16}}>Renovation Projects</text>
      </div>
      <div  style={{textAlign:'center',paddingLeft:button?70:'',display:'flex',alignItems:'center'}}>
      <text style={{marginRight:25,fontSize:16,color:'#c4baa6'}}>04</text>
      <text style={{fontWeight:'500',fontSize:16}}>Landscape</text>
      </div>
      <div  style={{textAlign:'center',paddingLeft:button?70:'',display:'flex',alignItems:'center'}}>
      <text style={{marginRight:25,fontSize:16,color:'#c4baa6'}}>05</text>
      <text style={{fontWeight:'500',fontSize:16}}>Project Management</text>
      </div>
    </div>
  </div>
 
  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',alignItems:'center',textAlign:'center',paddingTop:button?'20vh':'5vh',paddingBottom:button?'20vh':'5vh',fontFamily:'Montserrat',backgroundColor:'#f2f2f1'}}>
<div style={{display:'flex',flexDirection:'column',width:button?'':'25vw'}}>
<p style={{fontSize:18,fontWeight:'bold'}}>200,000+ sqft</p>
  <p >Area Delivered</p>
</div>

<div style={{display:'flex',flexDirection:'column',width:button?'':'25vw'}}>
<p style={{fontSize:18,fontWeight:'bold'}}>100+</p>
  <p>Completed Projects</p>
</div>
<div style={{display:'flex',flexDirection:'column',width:button?'':'25vw'}}>
<p style={{fontSize:18,fontWeight:'bold'}}>20+</p>
  <p style={{}}>Team Members</p>
</div>

</div>
   
<div className="scroll-container">
  <div className="scroll-text">
    <img  src={l1}/>
    <img   src={l2}/>
    <img   src={l3}/>
    <img   src={l4}/>
    <img   src={l5}/>
    <img   src={l6}/>
    <img   src={l7}/>
    <img   src={l8}/>
    <img  src={l1}/>
    <img   src={l2}/>
    <img   src={l3}/>
    <img   src={l4}/>
    <img   src={l5}/>
    <img   src={l6}/>
    <img   src={l7}/>
    <img   src={l8}/>
  </div>
  <div className="scroll-text">
  <img  src={l1}/>
    <img   src={l2}/>
    <img   src={l3}/>
    <img   src={l4}/>
    <img   src={l5}/>
    <img   src={l6}/>
    <img   src={l7}/>
    <img   src={l8}/>
    <img  src={l1}/>
    <img   src={l2}/>
    <img   src={l3}/>
    <img   src={l4}/>
    <img   src={l5}/>
    <img   src={l6}/>
    <img   src={l7}/>
    <img   src={l8}/>
  </div>
</div>
 

<img src={tad10} style={{width:'100%'}}/>
{/*<div  style={{backgroundColor:'white',textAlign:'center',display:'flex',justifyContent:'center',padding:'10vw'}}>
<text  style={{fontSize:button?26:18,width:button?'50vw':'',marginTop:'10vh',paddingBottom:'10vh',fontFamily:'Forum'}}>"Crafting not just spaces, but experiences that resonate with the pulse of modern living.."</text>
</div>*/}
    <div style={{width:button?'90%':'100vw',margin:button?'auto':'',textAlign:'center',padding:button?'10vw':'15vw'}}>
    <text style={{fontSize:12,letterSpacing:5}}>TESTIMONIAL</text>  
    <div  style={{paddingTop:50}}>
    <Slider {...settings}>
  {data.map((data)=>(<div style={{height:200,color:'black'}}>
  {/*<div style={{display:'flex',alignItems:'center',height:56,flexDirection:'column',marginBottom:20}}>
        <img style={{height:44,width:44,borderRadius:20}} src={tad10} />
        </div>*/}
     <div style={{display:'flex',flexDirection:'column',justifyItems:'center',alignItems:'center',marginBottom:50,textAlign:'center'}}>
     <text style={{width:button?600:''}}>{data.reveiew}</text>
       <text style={{paddingTop:25,fontWeight:'bold',fontSize:18}}>{data.name}</text>
       <text style={{color:'grey',fontSize:14}}>({data.company})</text>
     </div>
     </div>
  ))}
      </Slider>

      </div>
    </div>
 
    <Footer/>
    </div>
  );
}

export default Home;