import './Footer.css';
import React, { useState, useEffect, } from 'react'
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import logo from "./Assets/logo2.svg";
const Footer = () => {
  const [isDesktop, setIsDesktop] = useState(true);

  const handleResize = () => {
      if (window.innerWidth <= 960) {
          setIsDesktop(false);
      } else {
          setIsDesktop(true);
      }
  }

  useEffect(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
      
          <div className={isDesktop?'footer':'mobile'}>
        
            <img style={{height:35}} src={logo}/>
            <text style={{color:'grey',fontFamily:'Raleway',letterSpacing:1,fontSize:14}}>Architects</text>
    
            <div style={{borderTop:'1px solid black',marginTop:isDesktop?50:60,width:'90vw'}}></div>

                <div style={{justifyContent:'space-evenly',display:'flex',marginTop:20,width:'60vw',flexDirection:isDesktop?'row':'column'}}>
                        <a className={isDesktop?'menu':'menu foot'} href='/'>HOME</a>
                        <a className={isDesktop?'menu':'menu foot'} href='/projects'>PROJECTS</a>
      
                        <a className={isDesktop?'menu':'menu foot'} href='/about'>ABOUT US</a>
                        <a className={isDesktop?'menu':'menu foot'} href='/contact'>CONTACT US</a>
                    </div>
            
                  <div style={{display:'flex',flexDirection:'row',marginTop:isDesktop?70:100}}>
                  <FaInstagram style={{cursor:'pointer'}} onClick={()=>{window.open('https://www.instagram.com/triple__a_design/')}}   className='icon'/>
                  <FaFacebookF style={{marginLeft:20,marginRight:20,cursor:'pointer'}} onClick={()=>{window.open('https://www.facebook.com/share/XRFN4QRdo1M3uygw/?mibextid=LQQJ4d')}}  className='icon'/>
                  <FaLinkedin className='icon' style={{cursor:'pointer'}} onClick={()=>{window.open('https://in.linkedin.com/company/triple-a-design')}}  />
                  </div>
                
                <text style={{fontSize:14,marginTop:70,color:'grey',fontFamily:'Montserrat',textAlign:'center'}}>Copyright © {new Date().getFullYear()} TAD Pvt. Ltd., All rights reserved</text> 
              
  
    </div>
  
  );
};

export default Footer;