

import React, { useState, useEffect, } from 'react'
import tad14 from "../Assets/tad14.jpg";
import './About.css';
import con from "../Assets/contact.svg";
import pp from "../Assets/pp1.jpeg";
import d1 from "../Assets/d1.jpg";
import Footer from '../Footer';

function About() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
   <div>

   {/* <div className='about'>
  <div style={{display:'flex',flexDirection:'column',textAlign:'center',color:'white'}}>
        <text style={{fontSize:60,fontWeight:'bold'}}>Turn the Ordinary <br></br> into Extraordinary</text>
        </div>
  </div>*/}
     <div style={{display:'flex',flexDirection:'row',alignItems:'baseline',marginBottom:25,padding:button?'10vh':'10vw'}}>
     <text style={{fontSize:50,fontFamily:'Raleway'}}>About Us</text>
      <img style={{height:button?30:20,marginLeft:10}} src={con}></img>
      </div>
      <img  style={{width:'100%'}} src={d1}/>

  <div style={{display:'flex',flexDirection:'column',padding:button?'10vh':'4vh',}}>
      <text style={{fontSize:button?40:30,paddingBottom:button?60:25,textAlign:button?'right':'',}}>The core of our philosophy</text>
        <text style={{color:'gray',width:button?'60vw':'',fontSize:button?20:16}}>We believe in thoroughly grasping the distinctive essence and objectives of every project. We are dedicated to customizing our designs to reflect the aspirations and needs of our esteemed clientele. Throughout the collaborative journey, spanning from the initial conceptualization to the realization of the final built form, we commit fine attention to guarantee the seamless alignment of every detail with the overarching vision.</text>
 
    </div>
  
    <div style={{borderTop:'1px solid grey',margin:button?'10vh':'4vh'}}></div>
    <div style={{display:'flex',textAlign:'center',justifyContent:'center',paddingTop:button?'20vh':'10vh',paddingBottom:button?'30vh':'20vh',fontFamily:'Crimson'}}>
      <text style={{fontSize:button?26:20,color:'#ad8330',fontWeight:500,width:button?'50vw':'75vw'}}>"Refining the art of shaping environments that inspire, captivate, and transcend the ordinary."</text>
    </div>
    <div style={{display:'flex',flexDirection:button?'row':'column',padding:'5vw',backgroundColor:'#f2f2f1'}}>
<img src={pp} style={{height:button?'70vh':''}}  />
<div style={{display:'flex',flexDirection:'column',width:button?'50vw':'90vw',paddingLeft:button?100:0,justifyContent:'center',paddingTop:button?'':30,paddingBottom:button?'':50}}>
  <text  style={{letterSpacing:1,fontSize:24,color:'grey'}}>Ar. ARULALAN</text>
  <text style={{paddingTop:button?25:15,lineHeight:1.8}}>In 2019, Ar. Arulalan established Triple A Design (TAD). TAD, under Arulalan's leadership, has undertaken numerous projects in South India, with a primary focus on Hyderabad and Chennai. Specializing in residential and commercial architecture, Arulalan brings a diverse portfolio of designs to the table. He strongly advocates for collaborative and informative design as a cornerstone for successful projects. <br></br><br></br>Arulalan is driven by a passion for crafting spaces that positively impact people's daily lives, recognizing the pivotal role architects and spatial designers play in shaping perspectives on how spaces are utilized and perceived.</text>
</div>
</div>
 
 
<Footer/>
   </div>
  );
}

export default About;