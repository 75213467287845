

import React, { useState, useEffect, } from 'react'
import Footer from '../Footer';
import l3 from "../Assets/l3.png";
import con from "../Assets/contact.svg";
import './About.css';

function Contact() {

    const [button,setButton] = useState(true);


   

const showButton = () =>{
  if(window.innerWidth <= 960){
    setButton(false);
  }
  else{
    setButton(true);
  }
}

useEffect(()=>{
  showButton();
},[]);
  window.addEventListener('resize',showButton);


  return (
   <div>
    <div style={{padding:button?'10vh':'10vw'}}>
      <div style={{display:'flex',flexDirection:'row',alignItems:'baseline',marginBottom:25}}>
      <text style={{fontSize:50,fontFamily:'Raleway'}}>Get In Touch</text>
      <img style={{height:button?30:20,marginLeft:10}} src={con}></img>
      </div>
      <text style={{fontSize:16}}>Reach out to us through any of the channels below, and let’s <br></br>start crafting something extraordinary together.</text>
      <div style={{display:'flex',flexDirection:button?'row':'column',padding:40,textAlign:button?'':'center',borderTop:'1px solid black',borderBottom:'1px solid black',marginTop:button?'15vh':'10vh',marginBottom:'5vh'}}>
      <div style={{display:'flex',flexDirection:'column',color:'grey',fontSize:14,}}>

      <a href='tel:+917871846614' style={{color:"grey",paddingBottom:2}} >+91 7871846614</a>    
      <a href="mailto:contact@tripleadesign.in"  style={{color:"grey"}}>contact@tripleadesign.in</a>
      <a href="mailto:work@tripleadesign.in"  style={{color:"grey"}}>work@tripleadesign.in</a>
      </div>
      <div style={{display:'flex',flexDirection:'column',color:'grey',fontSize:14,paddingLeft:button?175:0,paddingTop:button?0:30}}>
      <text>Granduer spaces, First floor,</text>  
      <text>Road no .13, Banjara hills,</text>
      <text>Hyderbad, Telanagana - 500034</text>
      </div>
      <div style={{display:'flex',flexDirection:'column',color:'grey',fontSize:14,paddingLeft:button?175:0,paddingTop:button?0:30}}>
      <a href='https://maps.app.goo.gl/yikYXP8yHDJswMbK8'  target="_blank" style={{color:"grey",paddingBottom:2}}> Maps</a>
      <a href='https://www.instagram.com/triple__a_design/'  target="_blank" style={{color:"grey",paddingBottom:2}}>Instagram</a>
      <a href='https://www.facebook.com/share/XRFN4QRdo1M3uygw/?mibextid=LQQJ4d'  target="_blank" style={{color:"grey",paddingBottom:2}} > Facebook </a>

      </div>
      </div>
    </div>
    
<Footer/>
   </div>
  );
}

export default Contact;